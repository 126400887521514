import _ from "lodash";
import { useSelector } from "react-redux";
import { DataConstant } from "../../app/constants";

export const useUserType = () => {
  const { MasterAdmin, Admin, Receptionist, DoctorNetworkMD, Pharmacy } = DataConstant.userType;

  const { userType } = useSelector(({ auth }) => ({
    userType: auth.userType,
  }));

  const isMA = userType?.toLowerCase() === MasterAdmin?.toLowerCase();
  const isAdmin = userType?.toLowerCase(userType) === Admin?.toLowerCase();
  const isReceptionist = userType?.toLowerCase() === Receptionist?.toLowerCase();
  const isDNA = userType?.toLowerCase() === DoctorNetworkMD?.toLowerCase();
  const isPharmacy = userType?.toLowerCase() === Pharmacy?.toLowerCase();

  return { isMA, isAdmin, isReceptionist, isDNA, isPharmacy };
};
