import { lazy } from "react";

export const RoutesList = [
  {
    path: "/builder",
    component: lazy(() => import("./pages/BuilderPage")),
  },
  {
    path: "/dashboard",
    component: lazy(() => import("./modules/dashboard/dashboardPage")),
  },
  {
    path: "/states/new",
    component: lazy(() => import("./modules/state/stateAddEditDialog")),
  },
  {
    path: "/states/edit/:id",
    component: lazy(() => import("./modules/state/stateAddEditDialog")),
  },
  {
    path: "/states",
    component: lazy(() => import("./modules/state/statePage")),
  },
  {
    path: "error/error-v1",
    component: lazy(() => import("./modules/ErrorsExamples/ErrorPage4")),
  },
  {
    path: "/servicecategory",
    component: lazy(() =>
      import("./modules/services/serviceCategory/serviceCategoryPage")
    ),
  },
  {
    path: "/servicecategory/new",
    component: lazy(() =>
      import("./modules/services/serviceCategory/serviceCategoryAddEditDialog")
    ),
  },
  {
    path: "/servicecategory/edit/:id",
    component: lazy(() =>
      import("./modules/services/serviceCategory/serviceCategoryAddEditDialog")
    ),
  },
  {
    path: "/servicegroup",
    component: lazy(() =>
      import("./modules/services/serviceGroup/serviceGroupPage")
    ),
  },
  {
    path: "/servicegroup/new",
    component: lazy(() =>
      import("./modules/services/serviceGroup/serviceGroupAddEditDialog")
    ),
  },
  {
    path: "/servicegroup/edit/:id",
    component: lazy(() =>
      import("./modules/services/serviceGroup/serviceGroupAddEditDialog")
    ),
  },
  {
    path: "/onboarding",
    component: lazy(() =>
      import("./modules/onboardingFlow/onboardingFlowPage")
    ),
  },
  {
    path: "/adminStatus",
    component: lazy(() =>
      import("./modules/onboardingFlow/onboardingStepWiseReportPage")
    ),
  },
  {
    path: "/serviceProviderStatus",
    component: lazy(() =>
      import("./modules/onboardingFlow/onboardingStepWiseReportPage")
    ),
  },
  {
    path: "/hpsStandalone",
    component: lazy(() =>
      import("./modules/onboardingFlow/onboardingStepWiseReportPage")
    ),
  },
  {
    path: "/hpsBusiness",
    component: lazy(() =>
      import("./modules/onboardingFlow/onboardingStepWiseReportPage")
    ),
  },
  {
    path: "/medicalDirectorStatus",
    component: lazy(() =>
      import("./modules/onboardingFlow/onboardingStepWiseReportPage")
    ),
  },
  {
    path: "/receptionistStatus",
    component: lazy(() =>
      import("./modules/onboardingFlow/onboardingStepWiseReportPage")
    ),
  },
  {
    path: "/affiliateStatus",
    component: lazy(() =>
      import("./modules/onboardingFlow/onboardingStepWiseReportPage")
    ),
  },
  {
    path: "/clientStatus",
    component: lazy(() =>
      import("./modules/onboardingFlow/onboardingStepWiseReportPage")
    ),
  },
  {
    path: "/onboarding/new",
    component: lazy(() =>
      import("./modules/onboardingFlow/onboardingFlowAddEditDialog")
    ),
  },
  {
    path: "/onboarding/edit/:id",
    component: lazy(() =>
      import("./modules/onboardingFlow/onboardingFlowAddEditDialog")
    ),
  },
  {
    path: "/countries",
    component: lazy(() => import("./modules/country/countryPage")),
  },
  {
    path: "/countries/new",
    component: lazy(() => import("./modules/country/countryAddEditDialog")),
  },
  {
    path: "/countries/edit/:id",
    component: lazy(() => import("./modules/country/countryAddEditDialog")),
  },
  {
    path: "/business",
    component: lazy(() => import("./modules/business/businessPage")),
  },
  {
    path: "/business/new",
    component: lazy(() => import("./modules/business/businessAddEditDialog")),
  },
  {
    path: "/business/edit/:id",
    component: lazy(() => import("./modules/business/businessAddEditDialog")),
  },
  {
    path: "/parentBusiness",
    component: lazy(() => import("./modules/parentBusiness/parentBusinessPage")),
  },
  {
    path: "/parentBusiness/new",
    component: lazy(() => import("./modules/parentBusiness/parentBusinessAddEditDialog")),
  },
  {
    path: "/parentBusiness/edit/:id",
    component: lazy(() => import("./modules/parentBusiness/parentBusinessAddEditDialog")),
  },
  {
    path: "/onboardingformlinking/:onboardFlowId/new",
    component: lazy(() =>
      import("./modules/onboardingFlow/signupInfoRequestForm")
    ),
  },
  {
    path: "/onboardingformlinking/:onboardFlowId/edit/:id",
    component: lazy(() =>
      import("./modules/onboardingFlow/signupInfoRequestForm")
    ),
  },
  {
    path: "/subscriptions",
    component: lazy(() => import("./modules/subscriptions/subscriptionPage")),
  },
  {
    path: "/subscriptions/new",
    component: lazy(() =>
      import("./modules/subscriptions/subscriptionAddEditDialog")
    ),
  },
  {
    path: "/subscriptions/edit/:id",
    component: lazy(() =>
      import("./modules/subscriptions/subscriptionAddEditDialog")
    ),
  },
  {
    path: "/onboardingsteps/:onboardFlowId/new",
    component: lazy(() =>
      import("./modules/onboardingFlow/onboarding-steps/onboardingStepsForm")
    ),
  },
  {
    path: "/onboardingsteps/:onboardFlowId/edit/:stepId",
    component: lazy(() =>
      import("./modules/onboardingFlow/onboarding-steps/onboardingStepsForm")
    ),
  },
  {
    path: "/onboardingstepdetail/:stepId/new",
    component: lazy(() =>
      import(
        "./modules/onboardingFlow/onboarding-steps/onboardingStepSubDetailForm"
      )
    ),
  },
  {
    path: "/onboardingstepdetail/:stepId/edit/:onboardingStepSubDetailId",
    component: lazy(() =>
      import(
        "./modules/onboardingFlow/onboarding-steps/onboardingStepSubDetailForm"
      )
    ),
  },
  {
    path: "/subscriptionformlinking/:pid/new",
    component: lazy(() =>
      import("./modules/subscriptions/subscriptionPlanDialog")
    ),
  },
  {
    path: "/subscriptionformlinking/:pid/edit/:id",
    component: lazy(() =>
      import("./modules/subscriptions/subscriptionPlanDialog")
    ),
  },

  {
    path: "/facility",
    component: lazy(() => import("./modules/facility/facilityPage")),
  },
  {
    path: "/facility/new",
    component: lazy(() => import("./modules/facility/facilityAddEditDialog")),
  },
  {
    path: "/facility/edit/:id",
    component: lazy(() => import("./modules/facility/facilityAddEditDialog")),
  },
  {
    path: "/service",
    component: lazy(() => import("./modules/services/service/servicePage")),
  },
  {
    path: "/service/new",
    component: lazy(() =>
      import("./modules/services/service/serviceAddEditDialog")
    ),
  },
  {
    path: "/service/edit/:id",
    component: lazy(() =>
      import("./modules/services/service/serviceAddEditDialog")
    ),
  },
  {
    path: "/medicalassistant/:type",
    component: lazy(() =>
      import("./modules/serviceProvider/serviceProvider/serviceProviderPage")
    ),
  },
  {
    path: "/medicalassistant/:type/new",
    component: lazy(() =>
      import(
        "./modules/serviceProvider/serviceProvider/serviceProviderAddEditDialog"
      )
    ),
  },
  {
    path: "/medicalassistant/:type/edit/:id",
    component: lazy(() =>
      import(
        "./modules/serviceProvider/serviceProvider/serviceProviderAddEditDialog"
      )
    ),
  },
  {
    path: "/coupons",
    component: lazy(() => import("./modules/coupons/couponPage")),
  },
  {
    path: "/coupons/new",
    component: lazy(() => import("./modules/coupons/couponAddEditDialog")),
  },
  {
    path: "/coupons/edit/:id",
    component: lazy(() => import("./modules/coupons/couponAddEditDialog")),
  },
  {
    path: "/users",
    component: lazy(() => import("./modules/users/usersPage")),
  },
  {
    path: "/users/new",
    component: lazy(() => import("./modules/users/usersAddEditDialog")),
  },
  {
    path: "/users/edit/:id",
    component: lazy(() => import("./modules/users/usersAddEditDialog")),
  },

  {
    path: "/telemedicinesetup",
    component: lazy(() => import("./modules/telemedicine/setup/setupPage")),
  },
  {
    path: "/telemedicinepatientSpecific",
    component: lazy(() => import("./modules/telemedicine/setup/setupPage")),
  },
  
  {
    path: "/membershipplan",
    component: lazy(() =>
      import("./modules/membershipPlan/membershipPlanPage")
    ),
  },
  {
    path: "/membershipplan/new",
    component: lazy(() =>
      import("./modules/membershipPlan/membershipPlanAddEditDialog")
    ),
  },
  {
    path: "/membershipplan/edit/:id",
    component: lazy(() =>
      import("./modules/membershipPlan/membershipPlanAddEditDialog")
    ),
  },
  {
    path: "/insurancesettings",
    component: lazy(() => import("./modules/insurance/settings/settingsPage")),
  },
  
  {
    path: "/affiliate",
    component: lazy(() => import("./modules/affiliate/affiliatePage")),
  },
  {
    path: "/affiliateTracking",
    component: lazy(() => import("./modules/affiliate/affiliateTracking")),
  },
  {
    path: "/affiliate/new",
    component: lazy(() => import("./modules/affiliate/affiliateAddEditDialog")),
  },
  {
    path: "/affiliate/edit/:id",
    component: lazy(() => import("./modules/affiliate/affiliateAddEditDialog")),
  },
  {
    path: "/servicepack",
    component: lazy(() => import("./modules/servicePack/servicePackPage")),
  },
  {
    path: "/receptionist",
    component: lazy(() => import("./modules/receptionist/receptionistPage")),
  },
  {
    path: "/receptionist/new",
    component: lazy(() =>
      import("./modules/receptionist/receptionistAddEditDialog")
    ),
  },
  {
    path: "/receptionist/edit/:id",
    component: lazy(() =>
      import("./modules/receptionist/receptionistAddEditDialog")
    ),
  },
  {
    path: "/formType/:type",
    component: lazy(() =>
      import("./modules/servicePackForm/servicePackFormPage")
    ),
  },
  
  {
    path: "/location",
    component: lazy(() => import("./modules/location/locationPage")),
  },
  {
    path: "/metabase-reports/:id",
    component: lazy(() => import("./modules/reports/metabaseReport")),
  },
  {
    path: "/location/new",
    component: lazy(() => import("./modules/location/locationAddEditDialog")),
  },
  {
    path: "/location/edit/:id",
    component: lazy(() => import("./modules/location/locationAddEditDialog")),
  },
  {
    path: "/checklist",
    component: lazy(() =>
      import("./modules/telemedicine/checklist/checkListPage")
    ),
  },
  {
    path: "/giftcards",
    component: lazy(() => import("./modules/giftCard/giftCardPage")),
  },
  {
    path: "/giftcards/view/:id",
    component: lazy(() => import("./modules/giftCard/giftCardViewPage")),
  },
  {
    path: "/sp/negativebalance",
    component: lazy(() =>
      import(
        "./modules/dashboard/dashboardSections/serviceProviderWithNegativeBalancePage"
      )
    ),
  },
  {
    path: "/business/negativebalance",
    component: lazy(() =>
      import(
        "./modules/dashboard/dashboardSections/businessWithNegativeBalancePage"
      )
    ),
  },
  {
    path: "/condition",
    component: lazy(() => import("./modules/conditions/conditionPage")),
  },
  {
    path: "/condition/new",
    component: lazy(() =>
      import("./modules/conditions/conditionAddEditDialog")
    ),
  },
  {
    path: "/condition/edit/:id",
    component: lazy(() =>
      import("./modules/conditions/conditionAddEditDialog")
    ),
  },
  {
    path: "/medicalConsultSetting",
    component: lazy(() =>
      import("./modules/medicalConsults/medicalConsultationPage")
    ),
  },
  {
    path: "/payout",
    component: lazy(() => import("./modules/payout/payoutPage")),
  },
  {
    path: "/payout/edit/:id",
    component: lazy(() => import("./modules/payout/payoutEditDialog")),
  },
  {
    path: "/commissionsBalance",
    component: lazy(() =>
      import("./modules/commissionBalance/commissionBalancePage")
    ),
  },
  {
    path: "/tipsGratuity",
    component: lazy(() => import("./modules/tipsGratuity/tipsGratuityPage")),
  },
  {
    path: "/servicesalesReport",
    component: lazy(() =>
      import("./modules/serviceSalesReport/serviceSalesReportPage")
    ),
  },
  {
    path: "/clubReadyBookingSyncLog",
    component: lazy(() =>
      import("./modules/clubReadyBookingSyncLog/clubReadyBookingSyncLogPage")
    ),
  },
  {
    path: "/receipts",
    component: lazy(() => import("./modules/receipts/receiptsPage")),
  },
  {
    path: "/serviceaddonorder",
    component: lazy(() =>
      import("./modules/services/serviceAddOnOrder/serviceAddonOrderPage")
    ),
  },
  {
    path: "/psfSalesreport",
    component: lazy(() => import("./modules/salesReport/salesReportPage")),
  },
  {
    path: "/salesreport/medicalConsult/:id",
    component: lazy(() =>
      import("./modules/salesReport/medicalConsultEditDialog")
    ),
  },
  {
    path: "/salesreport/serviceBooking/:id",
    component: lazy(() =>
      import("./modules/salesReport/serviceBookingEditDialog")
    ),
  },
  {
    path: "/psfSalesReport/serviceBooking/:id/:sessionId",
    component: lazy(() =>
      import("./modules/salesReport/serviceBookingEditDialog")
    ),
  },
  {
    path: "/insuranceSalesReport",
    component: lazy(() =>
      import("./modules/insurance/salesReport/salesReportPage")
    ),
  },
  {
    path: "/insuranceSalesReport/edit/:id",
    component: lazy(() =>
      import("./modules/insurance/salesReport/salesReportEditDialog")
    ),
  },
  {
    path: "/serilog",
    component: lazy(() => import("./modules/serilog/seriLogPage")),
  },
  {
    path: "/emailtemplate",
    component: lazy(() => import("./modules/emailTemplate/emailTemplatePage")),
  },
  {
    path: "/consultation",
    component: lazy(() =>
      import("./modules/telemedicine/consultation/consultationPage")
    ),
  },
  {
    path: "/medicalScreeningSetting",
    component: lazy(() =>
      import("./modules/telemedicine/medicalScreeningSetting/medicalScreeningSetting")
    ),
  },

  {
    path: "/checkin",
    component: lazy(() =>
      import("./modules/telemedicine/consultation/checkinPage")
    ),
  },
  {
    path: "/checkin/:id",
    component: lazy(() =>
      import("./modules/telemedicine/consultation/checkinViewPage")
    ),
  },
  {
    path: "/consultation/:id",
    component: lazy(() =>
      import("./modules/telemedicine/consultation/consultationViewPage")
    ),
  },
  {
    path: "/medicalScreening/:id",
    component: lazy(() =>
      import("./modules/telemedicine/consultation/medicalScreeningViewPage")
    ),
  },
  {
    path: "/payoutAdjustments",
    component: lazy(() =>
      import("./modules/payoutAdjustments/payoutAdjustmentsPage")
    ),
  },
  {
    path: "/payoutAdjustments/new",
    component: lazy(() =>
      import("./modules/payoutAdjustments/payoutAdjustmentsAddEditDialog")
    ),
  },
  {
    path: "/payoutAdjustments/edit/:id",
    component: lazy(() =>
      import("./modules/payoutAdjustments/payoutAdjustmentsAddEditDialog")
    ),
  },
  {
    path: "/paymentConfigurations",
    component: lazy(() =>
      import("./modules/paymentConfigurations/paymentConfigurationsPage")
    ),
  },
  {
    path: "/paymentConfigurations/new",
    component: lazy(() =>
      import(
        "./modules/paymentConfigurations/paymentConfigurationAddEditDialog"
      )
    ),
  },
  {
    path: "/paymentConfigurations/edit/:id",
    component: lazy(() =>
      import(
        "./modules/paymentConfigurations/paymentConfigurationAddEditDialog"
      )
    ),
  },
  {
    path: "/businessPaymentConfiguration",
    component: lazy(() =>
      import("./modules/businessPaymentConfiguration/businessPaymentConfigurationList")
    ),
  },
  {
    path: "/businessPaymentConfiguration/new",
    component: lazy(() =>
      import(
        "./modules/businessPaymentConfiguration/businessPaymentConfigurationAddEdit"
      )
    ),
  },
  {
    path: "/businessPaymentConfiguration/edit/:id",
    component: lazy(() =>
      import(
        "./modules/businessPaymentConfiguration/businessPaymentConfigurationAddEdit"
      )
    ),
  },
  {
    path: "/trainingsubject",
    component: lazy(() =>
      import("./modules/trainingDashboard/trainingSubjects/trainingSubjectPage")
    ),
  },
  {
    path: "/trainingusers",
    component: lazy(() =>
      import("./modules/trainingDashboard/trainingUsers/trainingUsersPage")
    ),
  },
  {
    path: "/trainingdashboard",
    component: lazy(() =>
      import(
        "./modules/trainingDashboard/trainingDashboard/trainingDashboardPage"
      )
    ),
  },
  {
    path: "/trainingSubject/new",
    component: lazy(() =>
      import(
        "./modules/trainingDashboard/trainingSubjects/trainingSubjectAddEditDialog"
      )
    ),
  },
  {
    path: "/trainingSubject/edit/:id",
    component: lazy(() =>
      import(
        "./modules/trainingDashboard/trainingSubjects/trainingSubjectAddEditDialog"
      )
    ),
  },
  {
    path: "/trainingTopic/new",
    component: lazy(() =>
      import(
        "./modules/trainingDashboard/trainingSubjects/trainingTopicAddEditDialog"
      )
    ),
  },
  {
    path: "/trainingTopic/edit/:id",
    component: lazy(() =>
      import(
        "./modules/trainingDashboard/trainingSubjects/trainingTopicAddEditDialog"
      )
    ),
  },
  {
    path: "/trainingTest/new",
    component: lazy(() =>
      import(
        "./modules/trainingDashboard/trainingSubjects/trainingTestAddEditDialog"
      )
    ),
  },
  {
    path: "/trainingTest/edit/:id",
    component: lazy(() =>
      import(
        "./modules/trainingDashboard/trainingSubjects/trainingTestAddEditDialog"
      )
    ),
  },

  {
    path: "/trainingdashboard",
    component: lazy(() => import("./modules/trainingDashboard/trainingDashboard/trainingDashboardPage")),
  },

  {
    path: "/trainingsubject/edit/:id",
    component: lazy(() => import("./modules/trainingDashboard/trainingSubjects/trainingSubjectAddEditDialog")),
  },

  {
    path: "/traininguser/edit/:id",
    component: lazy(() => import("./modules/trainingDashboard/trainingSubjects/trainingSubjectAddEditDialog")),
  },

  {
    path: "/traininguser/view/:id",
    component: lazy(() => import("./modules/trainingDashboard/trainingUsers/trainingUserViewPage")),
  },

  {
    path: "/traininguser",
    component: lazy(() => import("./modules/trainingDashboard/trainingUsers/trainingUsersPage")),
  },

  {
    path: "/notification",
    component: lazy(() => import("./modules/notification/notificationPage")),
  },

  {
    path: "/notification/new",
    component: lazy(() => import("./modules/notification/notificationAddEditDialog")),
  },

  {
    path: "/notification/view/:id",
    component: lazy(() => import("./modules/notification/notificationAddEditDialog")),
  },
  {
    path: "/subscriptionReport",
    component: lazy(() =>
      import("./modules/subscriptionReport/subscriptionReportPage")
    ),
  },
  {
    path: "/clientReport",
    component: lazy(() =>
      import("./modules/clientReport/clientReportListPage")
    ),
  },
  {
    path: "/clientReportDetail/view/:id",
    component: lazy(() =>
      import("./modules/clientReport/clientReportDetailPage")
    ),
  },
  {
    path: "/clientReportDetail/view/soapNotesDetail/:id",
    component: lazy(() =>
      import("./modules/clientReport/clientReportSoapNotes/soapDetails")
    ),
  },
  {
    path: "/clientReportDetail/view/prescriptionDetail/:id",
    component: lazy(() =>
      import("./modules/clientReport/clientReportPrescription/prescriptionDetails")
    ),
  },
  {
    path: "/clientReportDetail/view/medicalHistoryDetail/:id/:orderid",
    component: lazy(() =>
      import("./modules/clientReport/clientMedicalHistory/clientMedicalHistoryDetails")
    ),
  },
  {
    path: "/orders/:type",
    component: lazy(() =>
      import("./modules/ordersOverview/ordersListPage")
    ),
  },
  {
    path: "/ordersOverview/:type/view/:id",
    component: lazy(() =>
      import("./modules/ordersOverview/orderDetailPage")
    ),
  },
  {
    path: "/preassesments",
    component: lazy(() =>
      import("./modules/preAssessments/PreAssessmentsList")
    ),
  },
  {
    path: "/addpreassessment",
    component: lazy(() =>
      import("./modules/preAssessments/PreAssessmentDetailPage")
    ),
  },
  {
    path: "/addpreassessment/edit/:id",
    component: lazy(() =>
      import("./modules/preAssessments/PreAssessmentDetailPage")
    ),
  },
  {
    path: "/incidentReportconfiguration",
    component: lazy(() =>
      import("./modules/incidentReport/incidentReportConfigurationPage")
    ),
  },
  {
    path: "/incidentReportList",
    component: lazy(() =>
      import("./modules/incidentReport/incidentReportListPage")
    ),
  },
  {
    path: "/serviceConsultReportDetail/view/prescriptionDetail/:id",
    component: lazy(() =>
      import("./modules/telemedicine/consultation/serviceConsultReportPrescription/servicePrescriptionDetails")
    ),
  },
  {
    path: "/serviceConsultReportDetail/view/soapNotesDetail/:id",
    component: lazy(() =>
      import("./modules/telemedicine/consultation/serviceConsultationReportSoapNotes/serviceConsultationsoapDetails")
    ),
  },
  {
    path: "/serviceConsultReportDetail/view/pharmacyOrderDetail/:id",
    component: lazy(() =>
      import("./modules/telemedicine/consultation/serviceConsultReportPharmacyOrderInfo/pharmacyOrderDetail")
    ),
  },
  {
    path: "/integration",
    component: lazy(() =>
      import("./modules/integration/integrationPage")
    ),
  },
  {
    path: "/support",
    component: lazy(() =>
      import("./modules/support/supportPage")
    ),
  },
  {
    path: "/support/:type",
    component: lazy(() => import("./modules/support/supportRequestPage")),
  },
  {
    path: "/mobileMedIVService",
    component: lazy(() =>
      import("./modules/mobileMedIVService/mobileMedIVServicePage")
    ),
  },
  {
    path: "/mobileMedIVService/new",
    component: lazy(() =>
      import("./modules/mobileMedIVService/mobileMedIVServiceAddEditDialog")
    ),
  },
  {
    path: "/mobileMedIVService/edit/:id",
    component: lazy(() =>
      import("./modules/mobileMedIVService/mobileMedIVServiceAddEditDialog")
    ),
  },
  {
    path: "/consultationWaitingList",
    component: lazy(() =>
      import("./modules/consultationWaitingList/consultationWaitingPage")
    ),
  },
  {
    path: "/consultationWaitingList/:id",
    component: lazy(() =>
      import("./modules/consultationWaitingList/consultationWaitingViewPage")
    ),
  },
  {
    path: "/upcoming-consults",
    component: lazy(() =>
      import("./modules/upcomingConsults/UpcomingConsults")
    ),
  },
  {
    path: "/upcoming-consults/:id",
    component: lazy(() =>
      import("./modules/telemedicine/consultation/consultationViewPage")
    ),
  }
];

export const InventoryRoutes = [
  {
    path: "/inventory",
    component: lazy(() => import("./modules/inventory/inventoryPage")),
  },
  {
    path: "/inventory/new",
    component: lazy(() => import("./modules/inventory/inventoryAddEditDialog")),
  },
  {
    path: "/inventory/edit/:id",
    component: lazy(() => import("./modules/inventory/inventoryAddEditDialog")),
  },
  {
    path: "/inventoryOrders",
    component: lazy(() =>
      import("./modules/inventoryOrders/inventoryOrdersPage")
    ),
  },
  {
    path: "/inventoryOrder/new",
    component: lazy(() =>
      import("./modules/inventoryOrders/inventoryOrderAddEditDialog")
    ),
  },
  {
    path: "/inventoryOrder/edit/:id",
    component: lazy(() =>
      import("./modules/inventoryOrders/inventoryOrderAddEditDialog")
    ),
  },
  {
    path: "/inventoryBarcodes",
    component: lazy(() =>
      import("./modules/inventoryBarcode/inventoryBarcodePage")
    ),
  },
  {
    path: "/inventoryUsed",
    component: lazy(() => import("./modules/inventoryUsed/inventoryUsedPage")),
  },
]

export const clientViewSetupRoutes = [
  {
    path: "/home",
    component: lazy(() =>
      import("./modules/homeScreenSetup/homeScreenSetupPage")
    ),
  },
  {
    path: "/event",
    component: lazy(() => import("./modules/event/eventPage")),
  },
  {
    path: "/event/new",
    component: lazy(() => import("./modules/event/eventAddEditDialog")),
  },
  {
    path: "/event/edit/:id",
    component: lazy(() => import("./modules/event/eventAddEditDialog")),
  },
  {
    path: "/spotlight",
    component: lazy(() => import("./modules/spotlight/spotlightPage")),
  },
  {
    path: "/spotlight/new",
    component: lazy(() => import("./modules/spotlight/spotlightAddEditDialog")),
  },
  {
    path: "/spotlight/edit/:id",
    component: lazy(() => import("./modules/spotlight/spotlightAddEditDialog")),
  },
  {
    path: "/news",
    component: lazy(() => import("./modules/news/newsPage")),
  },
  {
    path: "/news/new",
    component: lazy(() => import("./modules/news/newsAddEditDialog")),
  },
  {
    path: "/news/edit/:id",
    component: lazy(() => import("./modules/news/newsAddEditDialog")),
  },
  {
    path: "/medicalconsent",
    component: lazy(() =>
      import("./modules/medicalConsent/medicalConsentPage")
    ),
  },
  {
    path: "/clientBookingChoice",
    component: lazy(() =>
      import("./modules/clientBookingChoice/clientBookingChoicePage")
    ),
  },
]

export const marketingMaRoutes = [
  {
    path: "/lifetimerewardclub",
    component: lazy(() =>
      import("./modules/lifetimeRewardClub/lifetimeRewardClubPage")
    ),
  },
  {
    path: "/lifetimerewardclub/new",
    component: lazy(() =>
      import("./modules/lifetimeRewardClub/lifetimeRewardClubAddEditDialog")
    ),
  },
  {
    path: "/lifetimerewardclub/edit/:id",
    component: lazy(() =>
      import("./modules/lifetimeRewardClub/lifetimeRewardClubAddEditDialog")
    ),
  },
  {
    path: "/complimentaryIV",
    component: lazy(() =>
      import("./modules/complimentaryIV/complimentaryIVPage")
    ),
  },
  {
    path: "/complimentaryIV/new",
    component: lazy(() =>
      import("./modules/complimentaryIV/complimentaryIVAddEditDialog")
    ),
  },
  {
    path: "/complimentaryIV/edit/:id",
    component: lazy(() =>
      import("./modules/complimentaryIV/complimentaryIVAddEditDialog")
    ),
  },
  {
    path: "/complimentaryIVTopup/new/:id",
    component: lazy(() =>
      import(
        "./modules/complimentaryIV/complimentaryIVFormSections/addNewTopup"
      )
    ),
  },
]

export const salesReportMaRoutes = [
  {
    path: "/salesreport",
    component: lazy(() => import("./modules/salesReport/salesReportPage")),
  },
]

export const AllRoutseList = [
  ...RoutesList,
  ...InventoryRoutes,
  ...clientViewSetupRoutes,
  ...marketingMaRoutes,
  ...salesReportMaRoutes,
];
