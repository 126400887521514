import React, { Suspense, useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { AllRoutseList,  RoutesList} from "./routeList";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DataConstant, UrlConstant } from "./constants";
import { useUserType } from "../_metronic/_helpers/useUserType";
import { handleFetchGet } from "../utils/apiCallsProvider";
import { actionTypes } from "./modules/Auth/_redux/authRedux";
import { NotificationManager } from "react-notifications";

const { REACT_APP_WHITELABELNAME } = process.env;

export default function BasePage() {
  const dispatch = useDispatch();
  const {
    userType,
    businessId,
  } = useSelector(
    ({ auth }) => ({
      userType: auth.userType,
      businessId: auth.businessId,
    }),
    shallowEqual
  );
  const { isAdmin } = useUserType();
  const isVSDH = (REACT_APP_WHITELABELNAME?.toLowerCase() === DataConstant.whiteLabelNames.vsdigital.name.toLowerCase());
  const isVSDHAdminUser = isVSDH && isAdmin;
  const AllRouteList = isVSDHAdminUser ?  RoutesList : AllRoutseList;
  // if admin store if business is facility or not
  useEffect(() => {
    const getBusinessDetails = (id) => {
      handleFetchGet(`${UrlConstant.business.getBusinessById}/${id}`).then(
        (response) => {
          if (
            response &&
            response.status?.toLowerCase() ===
            DataConstant.apiResponseStatus.success.toLowerCase() &&
            response.data
          ) {
            let business = response.data;
            
            dispatch({
              type: actionTypes.UpdateUserDetail,
              payload: {
                isFacility: business?.isFacilityBooking
              }
            });
            
          } else {
            NotificationManager.error(response.message)
          }
        }
      ).catch((error) => {
        NotificationManager.error(error.message)
      });
    }
    if (isAdmin && businessId) {
      getBusinessDetails(businessId);
    }
    
  }, [isAdmin, businessId])
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {AllRouteList.map((route) => (
          <ContentRoute
            key={route.path}
            exact
            path={route.path}
            component={route.component}
          />
        ))}       
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
