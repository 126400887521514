import React, { useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";
import { Form, InputGroup } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useCommonContext } from "../context/commonContext";
import { actionTypes } from "../../redux/filter/filterReducer";
export const Filter = () => {
  const dispatch = useDispatch();


  const commonContext = useCommonContext();
  const commonProps = useMemo(() => {
    return {
      queryParams: commonContext.queryParams,
      setQueryParams: commonContext.setQueryParams,
    };
  }, [commonContext]);
  const intl = useIntl();
  const [searchText, setSearchText] = useState(commonContext.queryParams.searchText ?? "");

  useEffect(() => {
    if(!commonContext.queryParams.searchText)
      setSearchText("")
  }, [commonContext.queryParams.searchText])

  function applyFilter(queryParams, searchText) {
    const newQueryParams = { ...queryParams };
    newQueryParams.searchText = searchText;
    newQueryParams.page = 1;
    if (!isEqual(newQueryParams, queryParams)) {
      commonProps.setQueryParams(newQueryParams);
      dispatch({
        type: actionTypes.searchText,
        payload: {
          searchText: searchText,
        },
      });
    }
  }

  const handleEnterPress = (event) => {
    if (event?.key === "Enter") {
      event.preventDefault();
      applyFilter(commonProps.queryParams, searchText?.trim());
    }
  }

  return (
    <>
      <InputGroup className="mb-2">
        <InputGroup.Prepend>
          <InputGroup.Text id="basic-addon1">
            <i className="fa fa-search"></i>
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          type="text"
          onKeyPress={handleEnterPress}
          placeholder={intl.formatMessage({ id: "filter.search.placeholder" })}
          name="searchText"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </InputGroup>
    </>
  );
};
